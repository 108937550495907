import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  height: fit-content;
  margin: 2rem 0 10rem;
`;

const Title = styled.h2`
  margin-bottom: 3rem;
`;

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-auto-rows: auto;
  gap: 2rem;
`;

const Card = styled.div`
  width: 100%;
  height: fit-content;
`;

const CardImageLink = styled(Link)`
  width: 100%;
  height: 100%;
`;

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 45rem;
`;

const CardBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 0;
`;

const CardBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const CardTitle = styled.h3`
  margin-bottom: 0;
`;

const CardDate = styled.p`
  margin-bottom: 0;
`;

const CardDescription = styled.p`
  margin-bottom: 3rem;
`;

const CardLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme: { colors } }) => colors.mint};
  transition: all 0.3s ease-in;

  &:hover {
    color: ${({ theme: { colors } }) => colors.darkmint};
  }
`;

interface Datas {
  node: {
    frontmatter: Blog;
  };
}

interface Datas {
  node: {
    frontmatter: Blog;
  };
}

interface Blog {
  id: number;
  slug: string;
  title: string;
  date: string;
  description: string;
  images: {
    src: any;
    alt: string;
  };
}

const NewsRelated = ({ slug }: any) => {
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/news/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              id
              slug
              title
              date
              description
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `);

  const filterDatas = datas.allMarkdownRemark.edges.filter(
    ({ node }: Datas) => node.frontmatter.slug !== slug,
  );

  const newsRelatedDatas = filterDatas.map(({ node }: Datas) => (
    <Card key={node.frontmatter.id}>
      <CardImageLink to={`/post/${node.frontmatter.slug}`}>
        <CardImage
          image={node.frontmatter.images.src.childImageSharp.gatsbyImageData}
          alt={node.frontmatter.images.alt}
        />
      </CardImageLink>
      <CardBody>
        <CardBox>
          <CardTitle>{node.frontmatter.title}</CardTitle>
          <CardDate>{node.frontmatter.date}</CardDate>
        </CardBox>
        <CardDescription>{node.frontmatter.description.slice(0, 99)}...</CardDescription>
        <CardLink to={`/post/${node.frontmatter.slug}`}>{`Read Post...`}</CardLink>
      </CardBody>
    </Card>
  ));

  return (
    <Container>
      <Title>Related</Title>
      <GridBox>{newsRelatedDatas}</GridBox>
    </Container>
  );
};

export default NewsRelated;
