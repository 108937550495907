import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding-bottom: 5rem;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
`;

const Item = styled.li`
  padding: 0;

  & > a {
    text-transform: uppercase;
    font-size: 1.3rem;
    color: ${({ theme: { colors } }) => colors.mint};
    transition: all 0.3s ease-in;

    &:hover {
      color: ${({ theme: { colors } }) => colors.darkmint};
    }
  }
`;

const Pagination = ({ page }: any) => {
  const { prevPost, nextPost } = page;

  return (
    <Container>
      <List>
        <Item>
          {prevPost && (
            <Link to={`/post/${prevPost.frontmatter.slug}`} rel='prev'>{`<< prev`}</Link>
          )}
        </Item>
        <Item>
          {nextPost && (
            <Link to={`/post/${nextPost.frontmatter.slug}`} rel='next'>{`next >>`}</Link>
          )}
        </Item>
      </List>
    </Container>
  );
};

export default Pagination;
