import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import Seo from 'components/Seo/Seo';
import Layout from 'components/Layout/Layout';
import Pagination from 'components/News/Pagination';
import NewsRelated from 'components/News/NewsRelated';

import Theme from 'styles/_theme';
import GlobalStyle from 'styles/_global';
import { Wrapper } from 'styles/_mixins';

const Container = styled.div`
  width: 100%;
  height: fit-content;
`;

const Card = styled.div`
  width: 100%;
  height: fit-content;
`;

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 70rem;
`;

const CardBody = styled.div`
  width: 100%;
  height: fit-content;
  padding: 5rem 0;
`;

const CardBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const CardTitle = styled.h3`
  margin-bottom: 0;
`;

const CardDate = styled.p`
  margin-bottom: 1rem;
  font-size: 1.3rem;
`;

const CardDescription = styled.div`
  margin-bottom: 7rem;
  line-height: 2.5rem;

  & > p {
    margin-bottom: 2rem;
  }
`;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fileAbsolutePath: { regex: "/news/" }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        id
        slug
        title
        date
        description
        images {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: [AUTO, WEBP])
            }
          }
          alt
        }
      }
    }
  }
`;

const Post = ({ data, pageContext }: any) => {
  const { slug, title, date, description, images } = data.markdownRemark.frontmatter;

  return (
    <Theme>
      <GlobalStyle />
      <Seo title={title} description={description} />
      <Layout backgrounds>
        <Container>
          <Card>
            <CardImage
              image={images.src.childImageSharp.gatsbyImageData}
              alt={images.alt}
              objectFit='cover'
            />
            <Wrapper>
              <CardBody>
                <CardBox>
                  <CardTitle>{title}</CardTitle>
                  <CardDate>{date}</CardDate>
                </CardBox>
                <CardDescription>
                  <ReactMarkdown>{description}</ReactMarkdown>
                </CardDescription>

                <Pagination page={pageContext} />
              </CardBody>

              <NewsRelated slug={slug} />
            </Wrapper>
          </Card>
        </Container>
      </Layout>
    </Theme>
  );
};

export default Post;
